*{
  box-sizing: border-box;
  margin:0;
  padding:0;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #212121;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #484848;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}